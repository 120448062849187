a[href^="http://maps.google.com/maps"]{display:none !important}

.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
[class*='-keyboard-shortcuts-dialog-view']{
    display:none;
}
a[href^="https://maps.gstatic.com/mapfiles/api-3/images/google_white5_hdpi.png"]{display:none !important}

.gm-style-cc:last-child {
    display: none !important;
}
// remove the link only
a[title="Report errors in the road map or imagery to Google"] {
    display: none !important;
}

.button {
    display: flex;
    height: 50px;
    padding: 0;
    background: #009578;
    border: none;
    outline: none;
    border-radius: 5px;
    overflow: hidden;
    font-family: "Quicksand", sans-serif;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .button:hover {
    background: #008168;
  }
  
  .button:active {
    background: #006e58;
  }
  
  .button__text,
.buttonicon {
    display: inline-flex;
    align-items: center;
    padding: 0 24px;
    color: #fff;
    height: 100%;
  }
  
  .buttonicon {
    font-size: 1.5em;
    background: rgba(0, 0, 0, 0.08);
  }

  .pac-container {
    z-index: 1100 !important;
 }

 .searchicon {
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  color: #fff;
  height: 100%;
  background: rgba(0, 0, 0, 0.08);
}
  
  .gm-style-mtc,
  .gm-svpc,
  .gm-style-cc {
    right: 0px
    // display: none;
  }

  .gm-style a[href^="https://maps.google.com/maps"] {
    display: none !important;
  }
.gmnoprint {
  // display: none;
  right: 0px
}
// .dropdown-toggle {
//   margin-top: 36px;
//   min-width: 300px;
//   background-color: white;
//   color: darkslategrey;
// }

.currency-dropdown {
  max-height: 350px;
  overflow-y: scroll;
  min-width: 300px;

}