// 
// authentication.scss
//

// authentication pages background
body {
    &.authentication-bg {
        background-color: #3B2F63;
        background-image: radial-gradient(at 50% top, rgba(84, 90, 182, 0.6) 0%, rgba(84, 90, 182, 0) 75%), radial-gradient(at right top, #794aa2 0%, rgba(121, 74, 162, 0) 57%);
        background-repeat: no-repeat;
        background-size: 100% 1000px;
        background-size: cover;
        background-repeat: no-repeat;
        padding-bottom: 0;
    }
}


.authentication-form {
    .form-group {
        .input-group-text {
            background-color: transparent;

            svg {
                height: 22px;
                width: 22px;
            }
        }
    }
}

.icon-fb {
    color: #3c5a98;
}

.icon-google {
    color: #e94134;
}